import { FormatedImage } from '@pretto/website/src/components/FormatedImage/FormatedImage'
import { slugify } from '@pretto/website/src/templates/blocs/lib/slugify'

import React from 'react'

import * as S from './RowTimeLine.styles'

export type RowTimeLineProps = {
  buttonLabel?: string
  buttonType?: 'cta' | 'pretto_search'
  buttonUrl?: string
  entries: { entry: string }[]
  image: string
  isColored?: boolean
  isReversed?: boolean
  preamble?: string
  suptitle?: string
  title: string
}

export const RowTimeLine: React.FC<RowTimeLineProps> = ({
  buttonLabel,
  buttonType,
  buttonUrl,
  entries,
  image,
  isColored = false,
  isReversed = false,
  preamble,
  suptitle,
  title,
  ...props
}) => (
  <S.NewBlocRow isColored={isColored} isReversed={isReversed} {...props}>
    <S.ContentWrapper>
      {suptitle && <S.Suptitle>{suptitle}</S.Suptitle>}

      <S.Title id={slugify(title)}>{title}</S.Title>

      {preamble && <S.Preamble allowedTypes={['strong']}>{preamble}</S.Preamble>}

      {entries.length > 0 && (
        <ul>
          {entries.map(({ entry }, index) => (
            <S.Entry key={index} $isLast={index === entries.length - 1}>
              <S.Bullet />
              <S.EntryText allowedTypes={['a', 'strong']}>{entry}</S.EntryText>
            </S.Entry>
          ))}
        </ul>
      )}

      {buttonType === 'cta' && <S.Button href={buttonUrl}>{buttonLabel}</S.Button>}
      {buttonType === 'pretto_search' && <S.PrettoSearch />}
    </S.ContentWrapper>

    <S.ImageWrapper>
      <FormatedImage src={image} format="rectangle" isRounded />
    </S.ImageWrapper>
  </S.NewBlocRow>
)
