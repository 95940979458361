import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import BasePrettoSearch from '@pretto/bricks/website/about/components/PrettoSearch'
import RichContent from '@pretto/bricks/website/about/components/RichContent'

import { SecondaryButton } from '@pretto/zen/reveal/atoms/buttons/secondaryButton/SecondaryButton'

import { BulletBook } from '@pretto/picto'
import { NewBlocRow as NewBlocRowComponent } from '@pretto/website/src/templates/blocs/components/NewBlocRow'
import { anchorStyle } from '@pretto/website/src/templates/blocs/styles/anchorStyle'

import styled from 'styled-components'

type IsLastProps = {
  $isLast?: boolean
}

export const NewBlocRow = styled(NewBlocRowComponent)`
  ${grid()};
  background-color: ${({ isColored, theme }) => (isColored ? theme.colors.neutral4 : theme.colors.white)};
`

export const ContentWrapper = styled.div`
  ${column([2, 4])};
  display: flex;
  flex-direction: column;
  margin-bottom: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ theme }) => (theme.isReversed ? column([2, 6]) : column([8, 6]))};
    grid-row: 1;
    margin-bottom: unset;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${({ theme: { isReversed } }) => (isReversed ? column([3, 5]) : column([8, 5]))};
  }
`

export const Suptitle = styled.div`
  ${({ theme }) => theme.typos.heading6};
  margin-bottom: ${g(2)};
`

export const Title = styled.h2`
  ${anchorStyle};
  ${({ theme }) => theme.typos.heading4};
  margin-bottom: ${g(4)};
  hyphens: auto;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(3)};
    hyphens: unset;
  }
`

export const Preamble = styled(RichContent)`
  ${({ theme }) => theme.typos.body4};
  margin-bottom: ${g(2)};
`

export const Entry = styled.li<IsLastProps>`
  ${({ theme }) => theme.typos.body4};
  display: grid;
  grid-template-columns: ${g(3)} auto;
  column-gap: ${g(2)};
  position: relative;

  & + & {
    margin-top: ${g(2)};
  }

  &::before {
    background-color: ${({ theme }) => (theme.isColored ? theme.colors.primary2 : theme.colors.primary2)};
    border-radius: 1px;
    content: ${({ $isLast }) => ($isLast ? 'none' : `''`)};
    left: ${g(1, 3)};
    height: 100%;
    position: absolute;
    top: ${g(2, 4)};
    width: 2px;
    z-index: 1;
  }
`

export const Bullet = styled(BulletBook)`
  color: ${({ theme }) => (theme.isColored ? theme.colors.primary2 : theme.colors.primary2)};
`

export const EntryText = styled(RichContent)`
  ${({ theme }) => theme.typos.body4};
`

export const Button = styled(SecondaryButton).attrs({ scheme: 'black' })`
  margin-top: ${g(3)};
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-top: ${g(4)};
    margin-left: unset;
  }
`

export const PrettoSearch = styled(BasePrettoSearch)`
  margin-top: ${g(3)};
  justify-content: center;
  justify-items: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    justify-content: flex-start;
    justify-items: flex-start;
  }
`

export const ImageWrapper = styled.div`
  ${column([2, 4])};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: ${g(1)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([4, 4])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${({ theme: { isReversed } }) => (isReversed ? column([9, 5]) : column([2, 5]))};
    grid-row: 1;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    ${({ theme: { isReversed } }) => (isReversed ? column([9, 4]) : column([3, 4]))};
  }
`
